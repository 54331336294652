import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

// import Button from './styles/Button'
import StyledLink from './styles/StyledLink'

// import backgroundWatercolor from '../images/photos/background-watercolor.png'

import './spinningButton.css'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  margin-bottom: 56px;
`
const InnerContainer = styled.div`
  position: relative;
  padding-top: 44px;
  padding-bottom: 48px;
  border-top: 1px solid ${p => p.theme.lightGray};
  border-bottom: 1px solid ${p => p.theme.lightGray};
`

const Heading = styled.h1`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 2.6px;
  margin-bottom: 20px;
  @media (max-width: 550px) {
    font-size: 24px;
    letter-spacing: 2px;
  }
`

const SubContainer = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  /* position: relative; */
  width: 50%;
  /* padding-right: 60px; */
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
const Right = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: flex-end;
  padding-left: 100px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 0;
  }
`

const Input = styled.input`
  display: block;
  border: 1px solid ${p => p.theme.midGray};
  border-radius: 4px;
  padding: 11px 20px;
  width: 100%;
  margin-bottom: 20px;
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 380px;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
  top: 0px;
  left: -40px;
  @media (max-width: 1000px) {
    left: -60px;
    width: 300px;
    height: 201px;
  }
  @media (max-width: 400px) {
    height: 214px;
    left: -60px;
    width: 320px;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
`

const SmallText = styled.label`
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  margin-bottom: 20px;
  :before {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 2px;
    left: -12px;
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid ${p => p.theme.midGray};
  }
`

const Checkbox = styled.input`
  :checked + ${SmallText}:before {
    background-color: ${p => p.theme.primaryColor};
    transition: 0.15s;
    border: 1px solid ${p => p.theme.primaryColor};
  }
  :checked + ${SmallText}:after {
    content: '';
    position: absolute;
    top: 4px;
    left: -8px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    cursor: pointer;
  }
`

class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
  }

  state = {
    email: '',
    dsgvo: false,
    submitted: false,
    sendSuccess: false,
    sendFail: false,
  }

  handleEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  handleCheckboxChange = e => {
    this.setState({
      dsgvo: e.target.checked,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.submitted) {
      return
    }
    this.setState({
      submitted: true,
    })
    if (this.state.dsgvo === false) {
      return
    }
    const submitButton = this.buttonRef.current
    submitButton.classList.add('processing')
    // const url = 'https://api.newsletter2go.com/forms/submit/up3mherb-rfnk68ur-898'
    const url = 'https://api.newsletter2go.com/forms/submit/nshqmh6f-uq35dpbb-n34'
    const { email } = this.state
    const data = {
      recipient: {
        email,
      },
    }
    const fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    fetch(url, fetchData)
      .then(response => response.json())
      .then(data => {
        if (
          data &&
          data.value &&
          data.value[0] &&
          data.value[0].result &&
          data.value[0].result.error &&
          data.value[0].result.error.failed === 1
        ) {
          submitButton.classList.remove('processing')
          submitButton.classList.add('fail')
          this.setState({
            sendFail: true,
          })
        } else {
          submitButton.classList.remove('processing')
          submitButton.classList.add('done')
          this.setState({
            sendSuccess: true,
          })
        }
      })
      .catch(error => {
        submitButton.classList.remove('processing')
        submitButton.classList.add('fail')
        this.setState({
          sendFail: true,
        })
      })
  }

  render() {
    return (
      <Container>
        <InnerContainer>
          <SubContainer>
            <Left>
              <Heading>BLEIBEN SIE AM LAUFENDEN</Heading>

              <StaticQuery
                query={graphql`
                  {
                    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
                      childImageSharp {
                        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                      }
                    }
                  }
                `}
                render={data => <WaterColor alt="" image={data.watercolorImage.childImageSharp.gatsbyImageData} />}
              />

              <p style={{ marginBottom: '26px' }}>
                Sie wollen mehr über uns, über Events, Aktionen und vieles mehr erfahren? Melden Sie sich hier für
                unseren Newsletter an.
              </p>
              <p>
                Wir behandeln Ihre{' '}
                <StyledLink>
                  <Link to="/Datenschutzerklaerung">Daten</Link>
                </StyledLink>{' '}
                respektvoll und geben diese nicht an Dritte weiter.
              </p>
            </Left>
            <Right onSubmit={this.handleSubmit}>
              <Input
                type="email"
                id="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                required
                placeholder="E-Mail-Adresse"
                aria-label="E-Mail"
              />
              <CheckboxContainer>
                <Checkbox type="checkbox" id="dsgvo" required onChange={this.handleCheckboxChange} />
                <SmallText htmlFor="dsgvo">
                  Hiermit willige ich ein, den Newsletter der Weingut Edith Göschl GesbR an die genannte E-Mail-Adresse
                  zu erhalten. Ich kann meine Einwilligung jederzeit widerrufen. Weitere Informationen finden Sie{' '}
                  <StyledLink>
                    <Link to="/Datenschutzerklaerung">hier</Link>
                  </StyledLink>
                  .
                </SmallText>
              </CheckboxContainer>
              {/* <Button type="submit">ANMELDEN</Button> */}
              <div className="spinning-button-wrapper">
                <button type="submit" className="spin" id="spin" ref={this.buttonRef}>
                  <span className="spinning-senden">ANMELDEN</span>
                  <span className="spinning-check">
                    <svg viewBox="0 0 24 24">
                      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                    </svg>
                  </span>
                  <span className="spinning-x">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line
                        y1="-1"
                        x2="16.9706"
                        y2="-1"
                        transform="translate(18 5) rotate(135)"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <line
                        y1="-1"
                        x2="16.9706"
                        y2="-1"
                        transform="translate(19 17) rotate(-135)"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                </button>
                {this.state.sendSuccess && (
                  <span className="success-text">
                    Vielen Dank für die Anmeldung. Wir haben Ihnen eine E-Mail mit einem Bestätigungslink zugeschickt.
                  </span>
                )}
                {this.state.sendFail && (
                  <span className="fail-text">
                    Leider ist ein Fehler aufgetreten. Bitte schicken Sie eine E-Mail an{' '}
                    <StyledLink as="a" style={{ whiteSpace: 'nowrap' }} href="mailto:office@weingut-goeschl.at">
                      office@weingut-goeschl.at
                    </StyledLink>{' '}
                    um sich für den Newsletter anzumelden.
                  </span>
                )}
              </div>
            </Right>
          </SubContainer>
        </InnerContainer>
      </Container>
    )
  }
}

export default Newsletter
